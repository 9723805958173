export const tr = {
    hello: 'Merhaba',
    btn_send: 'Gönder',
    btn_login: 'Giriş Yap',
    btn_register: 'Üye Ol',
    btn_forgot_password: 'Şifremi Unuttum',
    btn_change_password: 'Şifremi Değiştir',


    input_your_name: 'Adınız ve Soyadınız',
    input_your_phone: 'Telefon Numaranız',
    input_your_email: 'E-Posta Adresiniz',
    input_your_password: 'Şifreniz',
    input_password_confirmation: 'Şifreniz (Tekrar)',
    input_your_username: 'Kullanıcı Adınız',
    input_your_message: 'Mesajınız',
    input_coupon_code: 'İndirim Kodunuz',

    input_choice: 'Seçiminiz',
    input_title: 'Başlık',
    input_company_name: 'Şirket Adı',
    input_phone: 'Telefon Numarası',
    input_phone_country: 'Ülke',
    input_country: 'Ülke',
    input_city: 'İl',
    input_town: 'İlçe',
    input_address: 'Adres',
    input_address_2: 'Adres 2',
    input_zip_code: 'Posta Kodu',
    input_address_type: 'Adres Türü',
    input_tax_no: 'Vergi Numarası',
    input_tax_center: 'Vergi Dairesi',
    input_first_name: 'Ad',
    input_last_name: 'Soyad',
    input_identification_no: 'T.C. Kimlik Numarası',
    input_exams: 'Sınavlar',
    input_years: 'Yıllar',
    input_dictionary_search: 'Sözlükte arama yap...',
}
